.ui.search .prompt {
  border-radius: 0rem;
  width: 300px;
  height: 35px;
}

.ui.search > .results {
  overflow-y: auto;
  max-height: 500px;
}

.ui.menu {
  font-size: medium;
  height: min-content;
  border-radius: 0px !important;
}

#wrap {
  overflow-wrap: break-word;
}

.ag-theme-balham {
  background-color: transparent;
  margin-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
}

.ag-paging-row-summary-panel {
  text-align: left;
}

.large.text {
  font-size: 16px;
}
